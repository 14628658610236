<template>
    <div>
        <el-form :model='formItem' :rules='rules' ref='formItem'>

            <el-form-item  label='名称' prop="name" :label-width="formLabelWidth">
                <el-input  placeholder='输入节日主题' v-model="formItem.name" show-word-limit maxlength="100"></el-input>
            </el-form-item>

            <el-form-item  prop="brandCode" label='品牌' :label-width="formLabelWidth">
                <el-select v-model="formItem.brandCode" clearable placeholder='品牌'  >
                    <el-option v-for="item in brandDicts" :key="item.code" :label="item.name" :value="item.code"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item  prop="countrys" label='国家' :label-width="formLabelWidth" >
                <el-checkbox-group v-model="formItem.countrys" >
                    <el-checkbox v-for="(cc, i) in countrysDicts" :label="cc.code" :key="i">
                        {{ cc.name}}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item  prop='recordDate' label='展示时间' :label-width='formLabelWidth'>
                <div class='block'>
                    <el-date-picker v-model='recordDate'
                        type='datetimerange'
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format='yyyy-MM-dd HH:mm:ss'
                        value-format='yyyy-MM-dd HH:mm:ss'
                        :default-time="['00:00:00', '23:59:59']"
                        @change='recordDateChange'
                    >
                    </el-date-picker>
                </div>
            </el-form-item>

            <el-form-item prop='status' label='是否启用' :label-width='formLabelWidth'>
                <el-switch v-model='formItem.status' active-color='#13ce66' inactive-color='#ff4949'></el-switch>
            </el-form-item>

            <el-form-item  prop='sort' label='排序' :label-width='formLabelWidth'>
                <el-input-number v-model="formItem.sort" :min="0" :max="10000" class='input-width'
                        placeholder='默认为0,数字越大,排序越靠前' ></el-input-number>
            </el-form-item>

            <el-form-item prop="imageUrl" label=' 图片' :label-width="formLabelWidth" class="must" >
                <el-col :span="6">
                    <imgUpload ref="imageUpload" :imgSize="1" :img="formItem.imageUrl" @event="changeUrl"> </imgUpload>
                </el-col>
                <el-col :span="6" style="margin-top: 100px">
                    <span>建议尺寸：432*96px，≤1MB</span>
                </el-col>
            </el-form-item>

        </el-form>

        <div  slot="footer" class='dialog-footer'>
            <el-button style='width: 20%' @click='closeDialog(false)'>取消</el-button>
            <el-button style='width: 20%' type='primary' :loading="loading" @click='onSubmit'>确认</el-button>
        </div>

    </div>
</template>
<script>
 
 
import moment from 'moment';
 
import imgUpload from '@/components/common/imgUpload';

export default {
    components: {
        imgUpload,
        moment
    },
    props: {
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        },
        countrysDicts: {
            type: Array,
            default: () => {
                return [];
            }
        },
        brandDicts: {
            type: Array,
            default: () => {
                return [];
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    filters: {
    },
    data() {
        let dateValid = (rule, value, callback) => {
            var selectedValue = this.recordDate;
            if (selectedValue == undefined || selectedValue == null || selectedValue.length < 2 || selectedValue[0] == '') {
                callback(new Error("请选择时间"));
            } else {
                callback();
            } 
        };
        return {
            formLabelWidth: '120px',
            recordDate: [],
            loading: false,
 
        
            rules: {
                name: [
                    {
                        required: true, message: "请输入节日主题", trigger: ['blur', 'change']
                    }
                ],
                brandCode: [
                    {
                        required: true, message: "请选择品牌", trigger: 'change'
                    }
                ],
                countrys: [
                    {
                        required: true, message: "请选择国家", trigger: 'change'
                    }
                ],
                recordDate: [
                    {
                        required: true, validator: dateValid, trigger: ['blur', 'change']
                    }
                ],
                sort: [
                    {
                        required: true, message: "请输入排序", trigger: ['blur', 'change']
                    }
                ],
                status: [
                    {
                        required: true, message: "请选择是否启用", trigger: ['blur', 'change']
                    }
                ],
            },
           
        };
    },
    created() {
    },
    mounted() {
        this.init()
    },
    methods: {
        init(){
            if(this.isEdit){

                this.recordDate = [moment(this.formItem.startTime ).format('YYYY-MM-DD HH:mm:ss'), moment(this.formItem.endTime ).format('YYYY-MM-DD HH:mm:ss')];
            }
        },

        recordDateChange(){
          if(this.recordDate != null && this.recordDate.length > 1){
                this.formItem.startTime = new Date(this.recordDate[0]).getTime();
                this.formItem.endTime = new Date(this.recordDate[1]).getTime();
          }
        },



        onSubmit() {
            this.$refs['formItem'].validate((valid,Object) => {
                if (valid) {

                    if( this.formItem.imageUrl==null ||  this.formItem.imageUrl==='' ){
                        this.$message({
                            showClose: false,
                            message: "请上传图片",
                            type: 'warning'
                        });
                        return;
                    }


                    this.$confirm( '是否提交数据', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {

                        this.formItem.logoConfigCountrys = this.formItem.countrys.map(x => ({countryCode:x}))
                        this.loading=true
                        
                        if (this.isEdit) {
                            this.$store.dispatch('putLogoConfig', this.formItem).then((res) => {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true,this.isEdit);
                            }).finally(() => {
                                this.loading = false;
                            });
                        } else {
                            this.$store.dispatch('addLogoConfig', this.formItem).then((res) => {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true,this.isEdit);
                            }).finally(() => {
                                this.loading = false;
                            });

                        }
                    }).catch(() => {
                    });
                } else {
                    this.$message({
                        message: '验证失败，请先输入必填项',
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        },

        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        },


        changeUrl(data) {
            this.formItem.imageUrl  = data;
        }
 
    }
};
</script>
<style scoped>

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

</style>
<style>
.must .el-form-item__label:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}


</style>