var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "formItem", attrs: { model: _vm.formItem, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "名称",
                prop: "name",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "输入节日主题",
                  "show-word-limit": "",
                  maxlength: "100"
                },
                model: {
                  value: _vm.formItem.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "name", $$v)
                  },
                  expression: "formItem.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "brandCode",
                label: "品牌",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "品牌" },
                  model: {
                    value: _vm.formItem.brandCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "brandCode", $$v)
                    },
                    expression: "formItem.brandCode"
                  }
                },
                _vm._l(_vm.brandDicts, function(item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "countrys",
                label: "国家",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.formItem.countrys,
                    callback: function($$v) {
                      _vm.$set(_vm.formItem, "countrys", $$v)
                    },
                    expression: "formItem.countrys"
                  }
                },
                _vm._l(_vm.countrysDicts, function(cc, i) {
                  return _c(
                    "el-checkbox",
                    { key: i, attrs: { label: cc.code } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(cc.name) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "recordDate",
                label: "展示时间",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00", "23:59:59"]
                    },
                    on: { change: _vm.recordDateChange },
                    model: {
                      value: _vm.recordDate,
                      callback: function($$v) {
                        _vm.recordDate = $$v
                      },
                      expression: "recordDate"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "status",
                label: "是否启用",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formItem.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "status", $$v)
                  },
                  expression: "formItem.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "sort",
                label: "排序",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input-number", {
                staticClass: "input-width",
                attrs: {
                  min: 0,
                  max: 10000,
                  placeholder: "默认为0,数字越大,排序越靠前"
                },
                model: {
                  value: _vm.formItem.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "sort", $$v)
                  },
                  expression: "formItem.sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "must",
              attrs: {
                prop: "imageUrl",
                label: " 图片",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("imgUpload", {
                    ref: "imageUpload",
                    attrs: { imgSize: 1, img: _vm.formItem.imageUrl },
                    on: { event: _vm.changeUrl }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "margin-top": "100px" }, attrs: { span: 6 } },
                [_c("span", [_vm._v("建议尺寸：432*96px，≤1MB")])]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("确认")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }