<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="searchData" :inline="true" size="small" label-width="140px">


                <el-form-item prop="brandCode">
                    <el-select v-model="searchData.brandCode" clearable placeholder='品牌' >
                        <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>     
               </el-form-item>

               <el-form-item prop="countryCode">
                    <el-select v-model="searchData.countryCode" clearable placeholder='国家' >
                        <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>     
               </el-form-item>

                <el-form-item prop="status">
                    <el-select v-model="searchData.status" clearable placeholder='是否启用'>
                        <el-option v-for="item in dictionary.status" :key="item.id" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-date-picker
                        v-model="time"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                      
                        format='yyyy-MM-dd HH:mm:ss'
                        value-format='yyyy-MM-dd HH:mm:ss'
                        :default-time="['00:00:00', '23:59:59']"
                    >
                    </el-date-picker>

                  
                </el-form-item>

                <el-form-item prop="name">
                    <el-input v-model="searchData.name" class='input-width' placeholder='请输入节日主题' clearable></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="handleSearchList">查询</el-button>
                    <el-button @click="handleResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </div>

        <div>
            <el-button style="margin: 10px 0px 10px 20px" type="primary" icon="el-icon-plus" @click="add()">
                添加
            </el-button>
        </div>

        <el-table ref="pagedata" v-loading="loading" :data="pageData.list"  border   style="width: 100%;">

            <el-table-column prop="brandCode" label='品牌' align="center" show-overflow-tooltip >
            </el-table-column>


            <el-table-column prop="countryCode" label='国家' align="center"   show-overflow-tooltip >
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.logoConfigCountrys" :key="index"> {{ $dict.getDictValue('country_ot', s.countryCode) }}<br /> </span>
                </template>
            </el-table-column>
 

            <el-table-column prop="name" label='节日主题' align="center"  show-overflow-tooltip>
            </el-table-column>


            <el-table-column prop="imageUrl" label='节日图片' align="center"  >

                <template slot-scope="scope">
                    <el-image
                        style="width: 100px; height: 100px"
                        v-if="scope.row.imageUrl != ''"
                        :src="ossHost + scope.row.imageUrl + '?x-oss-process=style/list_s'"
                        fit="fill"
                    ></el-image> </template>
            </el-table-column>

            <el-table-column prop="status" label='状态' align="center" >
                <template slot-scope="scope">  
                    <el-switch  v-model="scope.row.status" active-color="#13ce66" inactive-color='#ff4949' disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="startTime" label='开始时间' align="center"   show-overflow-tooltip :formatter="formatDate"></el-table-column>
            <el-table-column prop="endTime" label='结束时间'  align="center"  show-overflow-tooltip :formatter="formatDate"></el-table-column>
            <el-table-column prop="sort" label='排序' align="center"  show-overflow-tooltip></el-table-column>

            <el-table-column prop="operator" label='操作人' align="center"  show-overflow-tooltip></el-table-column>

            <el-table-column prop="updateTime" label='操作时间' align="center" show-overflow-tooltip :formatter="formatDate" >
            </el-table-column>

            <el-table-column label='操作' align="center"   >
                <template slot-scope="scope">
                    <el-button @click='edit(scope.row)' type='text' size='small'>编辑</el-button>
                    <el-button @click='del(scope.row)' type='text' size='small'>删除</el-button>
                </template>
            </el-table-column>
        </el-table>


        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="searchData.page"
                :page-size="searchData.limit"
                :page-sizes="pageSizes"
                layout="total,sizes, prev, pager, next, jumper"
                :total="pageData.total"
            ></el-pagination>
        </div>
 

        <el-dialog :title='isEdit ? "编辑" :  "新增"' :visible.sync="dialogFormVisible" v-if="dialogFormVisible" :close-on-click-modal="false">
            <add-update-dialog :isEdit="isEdit" :formItem="formItem" :countrysDicts="countrysDicts"  :brandDicts="brandDicts" @closeDialog="closeDialog" />
        </el-dialog>

    </div>
</template>

<script>
import moment from 'moment';
import AddUpdateDialog from './components/AddUpdateDialog.vue';
 

const defaultFormItem= {
    logoConfigId: null,
    name: '',
    brandCode:null,
    imageUrl:null,
    startTime:null,
    endTime:null,
    sort: 0,
    status: true,
    logoConfigCountrys: [],
    countrys: [],
}

const defaultDictionary = {  
    status: [{
        id: '1',
        name: '是',
        value: true
    }, {
        id: '2',
        name: '否',
        value: false
    }]
};

export default {
    name: 'LogoConfig',
    components: {
       moment,AddUpdateDialog
    },
    data() {
        return {
            dictionary: Object.assign({}, defaultDictionary),
            countrysDicts: [],
            brandDicts: [],
            time:[], 
            defaultSearchData:{
                brandCode: null,
                countryCode: null,
                status: null,
                startTime:null,
                endTime:null,
                name: null,
                page: 1,
                limit: 10,
            },
            searchData:{
                brandCode: null,
                countryCode: null,
                status: null,
                startTime:null,
                endTime:null,
                name: null,
                page: 1,
                limit: 10,
            },
            loading: false,
            pageSizes: [10, 20, 30, 50],
            pageData: {},
            ossHost: process.env.VUE_APP_OSS_Head,
           
            isEdit: false,
            dialogFormVisible: false,
            formItem: Object.assign({}, defaultFormItem),

        };
    },
    
    created() {
        this.getLangDictItems();
    },
    mounted() {
        this.handleSearchList();
    },
    methods: {
        getLangDictItems() {
            this.$store.dispatch('getDicItems', { tag: 'country_ot' }).then((res) => {
                if (res != null) {
                    this.countrysDicts = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) {
                    this.brandDicts = res;
                }
            });
        },

        //搜索列表
        handleSearchList() {
            this.searchData.page = this.defaultSearchData.page
            this.searchData.limit = this.defaultSearchData.limit 
            this.doQueryList();
        },
        //重置
        handleResetSearch() {
            this.time = [];
            this.searchData = JSON.parse(JSON.stringify(this.defaultSearchData));
            this.doQueryList();
        },
        // 点击页数查询
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.doQueryList();
        },
        // 点击条数查询
        handleSizeChange(val) {
            this.searchData.limit = val;
            this.doQueryList();
        },
        // 列表查询
        doQueryList() {
            if (this.time != null && this.time.length > 0) {
                this.searchData.startTime =  new Date(this.time[0]).getTime();   
                this.searchData.endTime =    new Date(this.time[1]).getTime();   
            } else {
                this.searchData.startTime = null;
                this.searchData.endTime = null;
            }

            let temp = JSON.stringify(this.searchData, (key, value) => {
                if (value !== null && value !== '') {
                    return value;
                }
            });
            let curSates = JSON.parse(temp);
            this.loading=true
            this.$store.dispatch('getLogoConfigPage', curSates).then(res => {
                this.pageData = res  
            }).finally(() => {
                this.loading = false;
            });
        },


        // 新增
        add(){
            this.formItem = JSON.parse(JSON.stringify(defaultFormItem))  
            if(this.brandDicts!=null && this.brandDicts.length > 0 ){
                this.formItem.brandCode = this.brandDicts[0].code
            }

            this.isEdit = false;
            this.dialogFormVisible = true;
        },

        // 编辑
        edit(row){
            row.countrys = [];
            this.formItem = JSON.parse(JSON.stringify(row))
                       
            if(this.formItem.logoConfigCountrys!=null){
                this.formItem.countrys = this.formItem.logoConfigCountrys.map(x => x.countryCode)    
            }

            // console.log(this.formItem)
            
            this.isEdit = true;
            this.dialogFormVisible = true;
        },

        // 新增编辑页面 关闭 
        closeDialog(status,isEdit){
            this.dialogFormVisible=false
            this.formItem=JSON.parse(JSON.stringify(defaultFormItem))    
            if(status){
                if(isEdit){
                    this.doQueryList();
                }else{
                    this.handleSearchList();					
                }   
            }		
        },
        
         // 删除
        del(row){
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteLogoConfig', {id: row.logoConfigId}).then(res => {
                    let totalPage = Math.ceil((this.pageData.total - 1) / this.searchData.limit)
                    let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                    this.searchData.page = currentPage < 1 ? 1 : currentPage
                    this.$message({
                        type: 'success',
                        message: '删除成功',
                    });
                    this.doQueryList();
                });
            }).catch(() => {});
        },
 

        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
</style>
